/* eslint-disable max-lines */
import { Box, List, ListItem, ListItemText, Menu, MenuItem, Avatar } from '@mui/material';
import { useRef, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { styled } from '@mui/material/styles';
import ExpandMoreTwoToneIcon from '@mui/icons-material/ExpandMoreTwoTone';
const ListWrapper = styled(Box)(
  ({ theme }) => `
        .MuiTouchRipple-root {
            display: none;
        }
        
        .MuiListItem-root {
            transition: ${theme.transitions.create(['color', 'fill'])};
            
            &.MuiListItem-indicators {
                padding: ${theme.spacing(1, 2)};
            
                .MuiListItemText-root {
                    .MuiTypography-root {
                        &:before {
                            height: 4px;
                            width: 22px;
                            opacity: 0;
                            visibility: hidden;
                            display: block;
                            position: absolute;
                            bottom: -10px;
                            transition: all .2s;
                            border-radius: ${theme.general.borderRadiusLg};
                            content: "";
                            background: ${theme.colors.primary.main};
                        }
                    }
                }

                &.active,
                &:active,
                &:hover {
                
                    background: transparent;
                
                    .MuiListItemText-root {
                        .MuiTypography-root {
                            &:before {
                                opacity: 1;
                                visibility: visible;
                                bottom: 0px;
                            }
                        }
                    }
                }
            }
        }
`,
);
const HeaderMenu = () => {
  const approvalsRef = useRef(null);
  const [isApprovalsOpen, setIsApprovalsOpen] = useState<boolean>(false);
  const handleOpenApprovals = (): void => {
    setIsApprovalsOpen(true);
  };

  const handleCloseApprovals = (): void => {
    setIsApprovalsOpen(false);
  };

  return (
    <>
      <ListWrapper
        sx={{
          display: {
            xs: 'none',
            md: 'block',
          },
        }}
      >
        <List disablePadding component={Box} display="flex">
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            ref={approvalsRef}
            onClick={handleOpenApprovals}
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary={
                <Box display="flex" alignItems="center">
                  Approvals
                  <Box display="flex" alignItems="center" pl={0.3}>
                    <ExpandMoreTwoToneIcon fontSize="small" />
                  </Box>
                </Box>
              }
            />
          </ListItem>
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            component={NavLink}
            to="/management/suppliers/all"
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary="Verified Suppliers"
            />
          </ListItem>
          <ListItem
            classes={{ root: 'MuiListItem-indicators' }}
            button
            component={NavLink}
            to="/management/unvalid-users"
          >
            <ListItemText
              primaryTypographyProps={{ noWrap: true }}
              primary="Non Completed Registrations"
            />
          </ListItem>
        </List>
      </ListWrapper>
      <Menu
        anchorEl={approvalsRef.current}
        onClose={handleCloseApprovals}
        open={isApprovalsOpen}
      >
        <MenuItem
          sx={{
            py: 1,
            display: 'flex',
            alignItems: 'center',
          }}
          component={NavLink}
          to="/management/suppliers/requests?status=Pending"
          onClick={handleCloseApprovals}
        >
          <Avatar sx={{ width: '1.9rem', height: '1.9rem', marginRight: '1rem' }}>
            <img alt="pending" src="/static/images/placeholders/icons/user.png" />
          </Avatar>
          Registration
        </MenuItem>
        <MenuItem
          sx={{
            py: 1,
            display: 'flex',
            alignItems: 'center',
          }}
          component={NavLink}
          to="/management/shops?status=Pending"
          onClick={handleCloseApprovals}
        >
          <Avatar sx={{ width: '1.9rem', height: '1.9rem', marginRight: '1rem' }}>
            <img alt="declined" src="/static/images/placeholders/icons/clock.png" />
          </Avatar>
          pending Shops
        </MenuItem>
        <MenuItem
          sx={{
            py: 1,
            display: 'flex',
            alignItems: 'center',
          }}
          component={NavLink}
          to="/management/products/?status=Pending"
          onClick={handleCloseApprovals}
        >
          <Avatar sx={{ width: '1.9rem', height: '1.9rem', marginRight: '1rem' }}>
            <img alt="declined" src="/static/images/placeholders/icons/product.png" />
          </Avatar>
          Products
        </MenuItem>
      </Menu>
    </>
  );
};

export default HeaderMenu;
